.background {
     position: fixed;
     top: 100;
     left: 0;
     width: 100%;
     height: 90%;
     z-index: -1; 
     background-image: url('../assets/images/background2.png');
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     filter: blur(7px); 
     opacity: 0.9;
   }

.Dashboard > h4 {
  text-transform: uppercase;
}

.miniNav {
  width: auto;
  padding: 5px var(--margin);
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: var(--margin);

  background-color: var(--bg-secondary);
  border-radius: 7px;
}
.miniNav > .line {
  border: 0.5px solid var(--gray-4);
}
.miniNav > a {
  padding: 5px 10px;
  border-radius: 5px;
}
.miniNav > a:hover {
  background-color: var(--bg-tertiary);
}

.addEmployeeButton {
  width: calc(100% - (var(--margin) * 2));
  max-width: var(--max-width-medium);
}
.addEmployeeButton > a {
  width: auto;
  align-items: center;
}

.addInventoryButton {
  width: calc(100% - (var(--margin) * 2));
  max-width: var(--max-width-medium);
  text-align: center;
}

.findEmployee .item > div {
  display: flex;
  flex-direction: row;
}
.findEmployee .item > div > input {
  width: 100%;
}