.splash {
  width: 100%;
  height: 600px;
  padding: 0 var(--margin);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--margin);

  background-color: var(--brown);
  background-image: linear-gradient(rgba(162, 132, 94, 0.8),
    rgba(162, 132, 94, 0.8)), url("../assets/images/landing.jpeg");
  background-size: cover;
  background-position: center;
}

.titles {
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: var(--margin);
}

.slogan {
  color: white;
}

.miniSlogan {
  color: white;
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 1.1em;
}

.landingForm {
  z-index: 1;
  width: 100%;
  max-width: var(--max-width-small);
  min-width: 215px;
  padding: var(--margin);

  display: flex;
  flex-direction: column;
  gap: var(--margin);

  border: 1px solid var(--border-form);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}
.landingForm > p {
  color: var(--brown);
}
.landingForm > button {
  margin: auto;
}
.trackTitle {
  margin: auto;
}
.trackingInput {
  padding: 1em;

  text-align: left;
  border-radius: 5px;
  border: 2px solid var(--border-form);
  width: 100%;
}

.landingboxes {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  gap: calc(var(--margin) * 2);
  transform: translateY(-70px);
}

.box {
  padding: var(--margin);
  max-width: 300px;

  display: flex;
  flex-direction: column;
  gap: calc(var(--margin) / 2);
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  background-color: var(--bg);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.icon {
  color: var(--brown);
  font-size: 4em;
}

@media screen and (max-width: 645px) {
  .splash {
    flex-direction: column;
    gap: calc(var(--margin) * 2);
  }

  .titles {
    max-width: var(--max-width-small);
  }

  .landingboxes {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(0px);
  }

  .box {
    max-width: var(--max-width-small);
  }
}