/* Team.css */
.team {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
