.ReactModal__Overlay {
    background-color: transparent; 
    
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;
  }

.profile-modal {
    width: 45rem;
    height: 36rem;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

.profileModalHeader {
    width: 100%;
    margin-bottom: 15px;

    display: flex;
    justify-content: right;
    align-items: center;
}
  
.ME_leftContainer {
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ME_leftbox {
    width: 250px;
    height: 350px;
    background-color: white;
    display: flex;
    justify-content: center; 
    align-items: center;
    border-top-left-radius: 3%;
    border-top-right-radius: 3%;
    border-color: #bbbbbb;
    border-width: 2px;
}

.CloseButton {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--red);
    padding: 0px 0px;
}

.ME_accountDetailsInfo {
    color: var(--brown);
    
    display: flex;
    justify-content: space-between;
    align-items: right;
    padding-top: 17px;
    width: 350px;
}

.ME_shippingDetailsInfo {
    color: var(--brown);
    
    display: flex;
    justify-content: space-between;
    align-items: right;
    padding-top: 15px;
    width: 350px;
}

.ME_label {
    font-size: 15px;
    margin-left: 20px;
}

.ME_userData {
    font-size: 15px;
    margin-right: 20px;
    font-weight: bold;
}

.pictureArea {
    width: 250px;
    height: 200px;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

}

.nameArea {
    width: 250px;
    height: 100px;
    background-color: white;
    z-index: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    top: -30px;

    margin-bottom: -30px;
}

.ME_name {
    color: var(--brown);
    font-weight: bolder;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;

}

.ME_leftunderboxes {
    z-index: 1;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;

}

.ME_underbox {
    width: 250px;
    height: 120px;
    background-color: #e6e5e5;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
    z-index: 1;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.ME_userHeader {
    width: 100%;
    height: 10px;
    background-color: #e6e5e5;
    z-index: 1;

    display: flex;
    justify-content: right;
    align-items: center;
  }

.ME_userEditIcon {
    color: var(--brown);
    font-size: 32px;
    cursor: pointer;

    position: relative;
    top: 15px;
}