.profileForm {
	width: 45rem;
    border: 2px solid var(--border-form);
	border-radius: 12px;
	background-color: var(--bg-form);
	padding: var(--margin);
    display: flex;
    justify-content: center;
}

.customerProfileForm {
    width: 70rem;
    border: 2px solid var(--border-form);
	border-radius: 12px;
	background-color: var(--bg-form);
	padding: var(--margin);
    display: flex;
    justify-content: center;
}

.leftContainer {
    display: flex;
    flex-direction: column;
}

.leftbox {
    width: 250px;
    height: 350px;
    background-color: white;
    display: flex;
    justify-content: center; 
    align-items: center;
    border-top-left-radius: 3%;
    border-top-right-radius: 3%;
    border-color: #bbbbbb;
    border-width: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pfpArea {
    width: 250px;
    height: 200px;
    margin-left: -32px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    top: -40px;

}

.profilepicture {
    width: 200px;
    height: 200px;
    background-image: url('../assets/images/profile.png');
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
    background-color: #cccccc;
    border-radius: 25%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: white;
    font-size: 200px; /* This line may not be necessary if using background-image */
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.customerName {
    color: var(--brown);
    font-weight: bolder;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.userRole {
    text-align: center;
    font-size: 20px;
}

.underbox {
    width: 250px;
    height: 130px;
    background-color: #e6e5e5;
    border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%;
    border-color: #bbbbbb;
    border-width: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.info {
    display: flex;
    align-items: center; 
    gap: .5em;
    font-weight: bold;
    color: var(--brown);
    font-size: 15px;
 }

.icon {
    font-size: 20px;
}
  
.info p {
    margin: 0; 
    display: flex;
    align-items: center; 
    padding-top: 5px;
    padding-bottom: 5px;
}

.customerDetails {
    display: grid;
    grid-template-columns: auto 1fr; 
    grid-gap: 10px; 
}
  
.customerDetails p {
    margin: 0;
    display: flex;
    align-items: center;
}

.rightContainer {
    padding-left: 40px;
    gap: 30px;
    display: flex;
    flex-direction: column;
}

.rightbox {
    background-color: white;
    width: 350px;
    height: 230px;
    border-radius: 2%;
    border-color: #bbbbbb;
    border-width: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.rightunder {
    background-color: white;
    width: 350px;
    height: 220px;
    border-radius: 2%;
    border-color: #bbbbbb;
    border-width: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.boxHeader {
    color: var(--brown);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
  
.headerText {
    font-size: 20px;
    margin-left: 20px;
}
  
.headerIcon {
    font-size: 32px;
    cursor: pointer;

    margin-right: 20px;
    margin-top: 10px;
}

.accountDetails {
    position: relative;
    top: -20px;
}

.accountDetailsInfo {
    color: var(--brown);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    width: 350px;
}

.label {
    font-size: 15px;
    margin-left: 20px;
}

.userData {
    font-size: 15px;
    margin-right: 20px;
    font-weight: bold;
}

.shippingDetails {
    position: relative;
    top: -15px;
}

.shippingDetailsInfo {
    color: var(--brown);
    
    display: flex;
    justify-content: space-between;
    align-items: right;
    padding-top: 10px;
    width: 350px;
}

.accountEditInfo {
    color: var(--brown);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    gap: 20px;
    width: 350px;

    position: relative;
    top: -20px;
}

.accountInput {
    color: var(--brown);
    border: none;
    width: 200px; 
    height: 20px;
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px;
    text-align: right;
}

.editLabel {
    font-size: 15px;
    margin-left: 20px;
}

.accountSaveButton {
    position: relative;
    right: -270px;
    top: -20px;
    width: 5px;
    height: 2px;
    margin-right: 20px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shippingEditInfo {
    color: var(--brown);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    width: 350px;

    position: relative;
    top: -10px;
}

.shippingInput {
    color: var(--brown);
    border: none;
    width: 170px; 
    height: 20px;
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px;
    text-align: right;
}

.shippingSaveButton {
    position: relative;
    right: -270px;
    top: -10px;
    width: 5px;
    height: 2px;
    margin-right: 20px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profileNameArea {
    width: 100%;
    height: 100px;

    position: relative;
    top: -110px;
    margin-bottom: -100px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.customerProfileArea {
    width: 100%;
    height: 100px;

    position: relative;
    top: -90px;
    margin-left: -32px;
    margin-bottom: -100px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.customerNameArea {
    width: 100%;
    height: 100px;

    position: relative;
    top: -110px;
    margin-bottom: -100px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Name {
    color: var(--brown);
    font-weight: bolder;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}

.userEditIcon {
    color: var(--brown);
    font-size: 32px;
    cursor: pointer;

    position: relative;
    top: 195px;
    right: -200px;
}

.customerUserEditIcon {
    color: var(--brown);
    font-size: 32px;
    cursor: pointer;

    position: relative;
    top: 195px;
    right: -200px;
}

.userInput {
    width: 100px;
    border: none;
    text-align: right;
}

.saveArea {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.userSaveButton {
    border-radius: 0%;
    width: 5px;
    height: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorHandler {
    width: 250px;
    height: 30px;

    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0px;
}

.accountErrorHandler {
    width: 250px;
    height: 30px;
    background-color: black;
}

.paymentContainer {
    width: 350px;
    height: 480px;
    margin-left: 40px;
    border-color: #bbbbbb;
    border-width: 2px;
    border-radius: 7px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
}

.paymentHeader {
    width: 350px;
    height: 55px;

    color: var(--brown);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.creditCardContainer {
    width: 350px;
    height: 225px;
    padding-bottom: 20px;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.creditCard {
    width: 290px;
    height: 180px;
    border-radius: 10px;
    background: linear-gradient(135deg, #ff4255, #ff5650, #ff674b, #ff7648, #ff8547);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    display: flex;
    justify-content: center;
    align-items: center;
}

.creditCardMargin {
    width: 260px;
    height: 150px;
}

.creditCardTop {
    width: 260px;
    height: 40px;

    display: flex;
    justify-content: right;
    align-items: center;
}

.visaLogo {
    color: white;
    font-size: 60px;
}

.creditCardMiddle {
    width: 260px;
    height: 65px;
    font-weight: 300;

    display: flex;
    justify-content: center;
    align-items: center;
}

.creditCardNumber {
    color: white;
    font-size: 26px;
}

.creditCardBottomHeader {
    width: 260px;
    height: 30px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.creditCardBottomTitles {
    font-size: 10px;
    color: white;
}

.creditCardBottom {
    width: 260px;
    height: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.creditCardBottomData {
    font-weight: 500;
    color: white;
}

.creditCardInfoContainer {
    width: 350px;
    height: 200px;
    background-color: #e6e5e5;
    padding-top: 5px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;

    box-shadow: 0 -4px 8px -4px rgba(0, 0, 0, 0.2);
}

.creditCardInfo {
    color: var(--brown);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 13px;
    width: 350px;
}

.userDataInput {
    color: var(--brown);
    background-color: transparent;
    border: none;
    width: 200px; 
    height: 20px;
    font-size: 15px;
    font-weight: bold;
    margin-right: 20px;
    text-align: right;
}

.paymentEditContainer {
    width: 350px;
    height: 175px;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.paymentEditArea {
    width: 350px;
    height: 150px;
}

.paymentSaveArea {
    width: 350px;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.paymentSaveButton {
    width: 5px;
    height: 5px;
    margin-right: 20px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}