.Header {
  height: var(--header-height);
  padding: 0 var(--margin);

  display: flex;
  align-items: center;
  justify-content: center;
  
  background-color: var(--brown);
  color: var(--white);
}

.Header a {
  position: absolute;
  right: var(--margin);
  width: auto;
  padding: 0;
  color: var(--white);
}

.company-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  /* color: var(--white); */
}

.link-two {
  padding-left: 200px;
}

.notification {
  position: absolute;
  left: var(--margin);
  width: auto;
  padding: 2px;
  cursor: pointer;

  border-radius: 50%;
  color: var(--brown);
  /* background-color: white; */
  font-size: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.notification > svg {
  color: var(--white);
}

.badge {
  position: absolute;
  padding: 0;
  margin: auto;
  top: 7px;
  right: 3px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: white;
  background-color: red;

  display: flex;
  justify-content: center;
  align-items: center;
} 

.dropdown-content {
  display: block; 
  position: absolute; 
  background-color: #fff; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  border-radius: 4px; 
  top: calc(100%); 
  left: 0; 
  z-index: 1000; 
  width: 500px;
  overflow-x: auto;
}

.dropdown-content li {
  list-style: none; 
  padding: 8px 12px; 
  cursor: pointer; 
}

.dropdown-content li:hover {
  background-color: #f4f4f4; 
}

.notification-header {
  font-size: 20px;
  color: white;
  background-color: var(--brown);
  height: 60px;
  padding: 10px;

  display: flex;
  justify-content: left;
  align-items: center;
}

.mark-all-btn {
  position: absolute;
  right: 6px;

  color: var(--brown);
  background-color: white;
  font-size: 12px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 10px;
  width: auto; 
  height: auto; 
  border: none;
  display: inline-block;
}

.notification-item {
  margin-bottom: 5px;
  border: 5px;
  font-size: 15px;
  border-color: black;
}
