.ReactModal__Overlay {
    background-color: transparent; 
    
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;
  }

.parcelModal {
    width: 40rem;
    align-content: center;
    height: 50rem;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center; 
    align-items: center;
  }

  